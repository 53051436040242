import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import CalendarMonthDayItem from '../CalendarMonthDayItem';
import CalendarDateIndicator from '../CalendarDateIndicator';
import CalendarDateSelector from '../CalendarDateSelector';
import CalendarWeekdays from '../CalendarWeekdays';
import useDaySummaries from '@/compositions/daysummaries';
import { multidayToSingleDays } from '@/classes/splitdates';
import useLocations from '@/compositions/locations';
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
export default defineComponent({
    name: 'CalendarMonth',
    components: {
        CalendarMonthDayItem,
        CalendarDateIndicator,
        CalendarDateSelector,
        CalendarWeekdays
    },
    setup() {
        return { ...useDaySummaries(), ...useLocations() };
    },
    data: () => ({
        selectedDate: dayjs(),
        dateFormat: 'YYYY-MM-DD',
        localFilter: {
            fromDate: new Date(),
            toDate: new Date()
        }
    }),
    computed: {
        days() {
            return [
                ...this.previousMonthDays,
                ...this.currentMonthDays,
                ...this.nextMonthDays
            ];
        },
        today() {
            return dayjs().format(this.dateFormat);
        },
        month() {
            return Number(this.selectedDate.format('M'));
        },
        year() {
            return Number(this.selectedDate.format('YYYY'));
        },
        numberOfDaysInMonth() {
            return dayjs(this.selectedDate).daysInMonth();
        },
        currentMonthDays() {
            return [...new Array(this.numberOfDaysInMonth)].map((_day, index) => {
                return {
                    date: dayjs(`${this.year}-${this.month}-${index + 1}`).format(this.dateFormat),
                    isCurrentMonth: true
                };
            });
        },
        previousMonthDays() {
            const firstDayOfThisMonth = this.currentMonthDays[0];
            if (firstDayOfThisMonth === undefined)
                return [];
            const firstDayOfTheMonthWeekday = this.getWeekday(firstDayOfThisMonth.date);
            const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(1, 'month');
            // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
            const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday ? firstDayOfTheMonthWeekday - 1 : 6;
            const previousMonthLastMondayDayOfMonth = dayjs(firstDayOfThisMonth.date)
                .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
                .date();
            return [...new Array(visibleNumberOfDaysFromPreviousMonth)].map((_day, index) => {
                return {
                    date: dayjs(`${previousMonth.year()}-${previousMonth.month() +
                        1}-${previousMonthLastMondayDayOfMonth + index}`).format(this.dateFormat),
                    isCurrentMonth: false
                };
            });
        },
        nextMonthDays() {
            const lastDayOfTheMonthWeekday = this.getWeekday(`${this.year}-${this.month}-${this.currentMonthDays.length}`);
            const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, 'month');
            const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
                ? 7 - lastDayOfTheMonthWeekday
                : lastDayOfTheMonthWeekday;
            return [...new Array(visibleNumberOfDaysFromNextMonth)].map((_day, index) => {
                return {
                    date: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format(this.dateFormat),
                    isCurrentMonth: false
                };
            });
        },
        daySummaryFilter() {
            return this.$store.direct.state.daysummaries.filter;
        },
        occupiedDays() {
            return multidayToSingleDays(this.daySummaryFilter.fromDate, this.daySummaryFilter.toDate);
        }
    },
    watch: {
        selectedDate: {
            handler() {
                this.localFilter.fromDate = new Date(dayjs(this.selectedDate).startOf('month').format());
                this.localFilter.toDate = new Date(dayjs(this.selectedDate).endOf('month').format());
                this.$store.direct.dispatch.daysummaries.applyFilter(this.localFilter);
            },
            immediate: true
        }
    },
    methods: {
        getWeekday(date) {
            return dayjs(date).weekday();
        },
        selectDate(newSelectedDate) {
            this.selectedDate = newSelectedDate;
        },
        isOccupied(dayFrom, dayTo) {
            var _a;
            if (this.currentLocation === undefined) {
                return 0;
            }
            return this.multiDaySummary.numOpenSpots(dayFrom, dayTo, (_a = this.currentLocation) === null || _a === void 0 ? void 0 : _a.numberOfSpots);
        },
        isOccupiedAM(day) {
            const date = new Date(day);
            const prevDate = new Date(date.getTime() - 12 * 60 * 60 * 1000);
            return this.isOccupied(prevDate, date);
        },
        isOccupiedPM(day) {
            const date = new Date(day);
            const afternoon = new Date(date.getTime() + 12 * 60 * 60 * 1000);
            const nextDay = new Date(date.getTime() + 30 * 60 * 60 * 1000);
            return this.isOccupied(afternoon, nextDay);
        }
    }
});
