import { defineComponent } from 'vue';
const WEEKDAYS = [
    'Ma',
    'Di',
    'Wo',
    'Do',
    'Vr',
    'Za',
    'Zo'
];
export default defineComponent({
    name: 'CalendarWeekdays',
    computed: {
        weekdays() {
            return WEEKDAYS;
        }
    }
});
