import { defineComponent } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
    name: 'CalendarMonthDayItem',
    props: {
        day: {
            type: Object,
            required: true
        },
        isCurrentMonth: {
            type: Boolean,
            default: false
        },
        isToday: {
            type: Boolean,
            default: false
        },
        isOccupiedAM: {
            type: Number,
            default: 0,
            required: false
        },
        isOccupiedPM: {
            type: Number,
            default: 0,
            required: false
        }
    },
    computed: {
        label() {
            return dayjs(this.day.date).format('D');
        },
        // eslint-disable-next-line complexity
        className() {
            let classStr = '';
            if (!this.day.isCurrentMonth)
                classStr += 'calendar-day--not-current ';
            if (this.isToday)
                classStr += 'calendar-day--today ';
            if (this.isOccupiedAM === 0 && this.isOccupiedPM === 0)
                classStr += 'calendar-day--occupied complete ';
            else if (this.isOccupiedAM < 5 && this.isOccupiedPM < 5)
                classStr += 'calendar-day--almost-occupied complete ';
            if (classStr.includes('complete'))
                return classStr;
            if (this.isOccupiedAM > 0 && this.isOccupiedPM === 0)
                classStr += 'calendar-day--occupied pm ';
            else if (this.isOccupiedAM > 0 && this.isOccupiedPM < 5)
                classStr += 'calendar-day--almost-occupied pm ';
            if (this.isOccupiedAM === 0 && this.isOccupiedPM > 0)
                classStr += 'calendar-day--occupied am ';
            else if (this.isOccupiedAM < 5 && this.isOccupiedPM > 0)
                classStr += 'calendar-day--almost-occupied am ';
            return classStr;
        }
    }
});
