import { defineComponent } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
    name: 'CalendarModeSelector',
    props: {
        currentDate: {
            type: String,
            required: true
        },
        selectedDate: {
            type: Object,
            required: true
        }
    },
    emits: {
        dateSelected(_date) { return true; }
    },
    methods: {
        selectPrevious() {
            const newSelectedDate = dayjs(this.selectedDate).subtract(1, 'month');
            this.$emit('dateSelected', newSelectedDate);
        },
        selectCurrent() {
            const newSelectedDate = dayjs(this.currentDate);
            this.$emit('dateSelected', newSelectedDate);
        },
        selectNext() {
            const newSelectedDate = dayjs(this.selectedDate).add(1, 'month');
            this.$emit('dateSelected', newSelectedDate);
        }
    }
});
