import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        selectedDate: {
            type: Object,
            required: true
        }
    },
    computed: {
        selectedMonth() {
            return this.selectedDate.format('MMMM YYYY');
        }
    }
});
